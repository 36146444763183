import React, { useRef, useState } from 'react'
import { Banner } from 'components/shared/Banner/Banner'
import Icon from 'components/shared/Icon/Icon'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import LanguageSelector from './LanguageSelector/LanguageSelector'
import NavItemLink from './NavItemLink/NavItemLink'
import './SiteHeader.css'
import UserStatus from './UserStatus/UserStatus'
import logomark from './images/rpf.svg'

const navItems = {
  pathways: { to: 'paths' },
  projects: { to: 'projects' },
}

const NavDropdown = ({ navItemKey, locale, t }) => {
  const dropdownRef = useRef(null)
  return (
    <div
      role="button"
      onKeyDown={(e) =>
        e.key === 'Enter' ? dropdownRef.current?.click() : null
      }
      tabIndex={0}
    >
      <label className="c-site-header__dropdown">
        <input
          className="c-site-header__nav-link c-site-header__dropdown-button u-hidden"
          type="checkbox"
          ref={dropdownRef}
        />
        <div className="c-site-header__dropdown-background">
          {t(`navigation.${navItemKey}`)}
          <Icon
            type="arrow-down"
            className="c-site-header__toggle-trigger-icon"
          />
        </div>
        <ul className="c-site-header__dropdown-content">
          {Object.entries(navItems[navItemKey].dropdown).map(([key, value]) => (
            <NavItemLink
              key={`${navItemKey}__${key}-dropdown-link`}
              listKey={`${navItemKey}__${key}-dropdown-link`}
              locale={locale}
              linkTo={value.to}
              translateKey={key}
              t={t}
            />
          ))}
        </ul>
        <ul className="c-site-header__nested-links">
          {Object.entries(navItems[navItemKey].dropdown).map(([key, value]) => (
            <NavItemLink
              key={`${navItemKey}__${key}-nested-link`}
              listKey={`${navItemKey}__${key}-nested-link`}
              locale={locale}
              linkTo={value.to}
              translateKey={key}
              t={t}
            />
          ))}
        </ul>
      </label>
    </div>
  )
}

NavDropdown.propTypes = {
  navItemKey: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export const SiteHeader = ({ i18n, t }) => {
  const searchParams = useSearchParams()[0]
  const branch = searchParams.get('branch') || ''

  const [navOpen, setNavOpen] = useState(false)

  const toggleHandler = () => {
    setNavOpen(!navOpen)
  }

  return (
    <header
      className="c-site-header"
      id="c-site-header"
      role="banner"
      data-nav-open={navOpen}
    >
      <a className="c-site-header__skiptocontent-link" href="#skiptocontent">
        Skip to main content
      </a>
      <div className="c-site-header__container">
        <div className="c-site-header__home-link">
          <a className="c-site-header__rpf-link" href="https://raspberrypi.org">
            <img
              alt="Raspberry Pi Foundation"
              className="c-site-header__rpf-logomark"
              src={logomark}
              aria-label="Raspberry Pi Foundation home page"
            />
          </a>
        </div>

        <div className="c-site-header__toggle">
          <button
            aria-label="Open Navigation"
            onClick={toggleHandler}
            aria-pressed={navOpen}
            className="c-site-header__toggle-button"
            type="button"
          ></button>
        </div>

        <div className="site-header__nav">
          <nav
            aria-label="Navigation"
            className="site-header-nav"
            data-js="nav"
            data-active-panel=""
          >
            <ul className="c-site-header-nav__list">
              <NavItemLink
                key={'home-item'}
                listKey={'home-item'}
                locale={i18n.language}
                linkTo=""
                translateKey={'home'}
                t={t}
              />
              <React.Fragment>
                {Object.entries(navItems).map(([key, value]) =>
                  value?.to ? (
                    <NavItemLink
                      key={`${key}-item`}
                      listKey={`${key}-item`}
                      locale={i18n.language}
                      linkTo={value.to}
                      translateKey={key}
                      t={t}
                    />
                  ) : (
                    <li
                      key={`${key}-item`}
                      className="c-site-header-nav__item"
                      data-js="nav-item"
                      data-section={key}
                    >
                      {value?.dropdown && (
                        <NavDropdown
                          navItemKey={key}
                          locale={i18n.language}
                          t={t}
                        />
                      )}
                    </li>
                  )
                )}
                <UserStatus
                  className="c-site-header__nav-link"
                  locale={i18n.language}
                />
              </React.Fragment>
            </ul>
          </nav>
        </div>
        <div className="c-site-header__language-selector">
          <span className="c-site-header__language-selector--language">
            {t('language-selector.language')}
          </span>
          <LanguageSelector />
        </div>
      </div>
      {branch && <Banner text={`${t('dev.branch_banner')}: ${branch}`} />}
    </header>
  )
}

SiteHeader.propTypes = {
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default translate('translations')(SiteHeader)
