import React, { Suspense } from 'react'
import {
  AuthenticationContainerLoader,
  CollectionContainerLoader,
  CollectionListLoader,
  ExerciseContainerLoader,
  HomepageLoader,
  PathwayContainerLoader,
  ProjectContainerLoader,
  ProjectEditorContainerLoader,
  ProjectListContainerLoader,
  ProjectPrintContainerLoader,
  SilentRenewContainerLoader,
  UserProjectListContainerLoader,
} from 'AsyncLoaders'
import loadArchivedRoutes from 'components/ArchivedRedirects'
import loadLegacyRoutes from 'components/LegacyRedirects'
import loadCodeClubRedirect from './CodeClubRedirect'
import Footer from 'components/shared/Footer/Footer'
import NoMatch from 'components/shared/NoMatch/NoMatch'
import SiteHeader from 'components/shared/SiteHeader/SiteHeader'
import config from 'helpers/config'
import setUpHistory from 'helpers/history'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

const NoMatchHandler = translate('translations')(({ t }) => (
  <div>
    <SiteHeader locale="en" />
    <NoMatch
      error={{
        status: config.notFoundStatus,
        message: t('no-match.title.text'),
      }}
    />
    <Footer
      text="project.footer.feedback.homepage.text"
      userID="no-match-footer-user-id"
    />
  </div>
))

const AppRoutes = ({ i18n }) => (
  <BrowserRouter history={setUpHistory(i18n)}>
    <Suspense fallback={<p> Loading...</p>}>
      <Routes>
        <Route
          path="rpi/silent_renew"
          element={<SilentRenewContainerLoader />}
        />
        {loadLegacyRoutes()}
        {loadArchivedRoutes()}
        <Route path="/" element={<Navigate to="/en" />} />
        <Route path="/:locale" element={<HomepageLoader />} />
        <Route
          path="/:locale/exercises/:slug/:complete?"
          element={<ExerciseContainerLoader />}
        />
        {loadCodeClubRedirect()}
        <Route path="/:locale/paths" element={<CollectionListLoader />} />
        <Route path=":locale/jam" element={<Navigate to="jam" />} />
        {/* TODO: is this route needed? */}
        <Route path=":locale/collections" element={<CollectionListLoader />} />
        <Route
          path=":locale/collections/:slug"
          element={<CollectionContainerLoader />}
        />
        <Route
          path=":locale/projects"
          element={<ProjectListContainerLoader />}
        />
        <Route
          path=":locale/projects/favourites"
          element={<UserProjectListContainerLoader tab="favourites" />}
        />
        <Route
          path=":locale/projects/progress"
          element={<UserProjectListContainerLoader tab="progress" />}
        />
        <Route
          path=":locale/projects/finished"
          element={<UserProjectListContainerLoader tab="finished" />}
        />
        <Route
          path=":locale/projects/:slug/print"
          element={<ProjectPrintContainerLoader />}
        />
        <Route
          path=":locale/projects/:slug/complete"
          element={<ProjectContainerLoader />}
        />
        <Route path=":locale/projects/:slug">
          <Route path=":position" element={<ProjectContainerLoader />} />
          <Route
            path="editor"
            element={<ProjectEditorContainerLoader />}
          ></Route>

          <Route index element={<ProjectContainerLoader />} />
        </Route>
        <Route
          path=":locale/:pathwayType/:slug"
          element={<PathwayContainerLoader />}
        />
        <Route
          path="rpi/callback"
          element={<AuthenticationContainerLoader />}
        />
        <Route element={<NoMatchHandler />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
)

AppRoutes.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default translate('translations')(AppRoutes)
