import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

export const archivedPages = ['codeclub', 'coderdojo']

export const Redirect = ({ to }) => {
  window.location.replace(to)
  return null
}

Redirect.propTypes = {
  to: PropTypes.string,
}

const loadCodeClubRedirect = () => {
  return archivedPages.map((page, idx) => {
    return (
      <Route
        path={`/:locale/${page}`}
        key={idx}
        element={<Redirect to={`https://codeclub.org/learn-to-code`} />}
      />
    )
  })
}

export default loadCodeClubRedirect
